<template>
  <article v-bind="attrs">
    <!-- Actions -->
    <div class="user-card__row">
      <div class="group">
        <!-- Default -->
        <data-tag v-if="address.isDefault">{{ $t('resto.is_default') }}</data-tag>

        <!-- Set as default -->
        <actions-button
          :size="$pepper.Size.S"
          @click="onSetAsDefault"
          v-if="!address.isDefault"
        >{{ $t('resto.set_default') }}</actions-button>
      </div>

      <div class="user-card__row">
        <actions-button
          :size="$pepper.Size.S"
          @click="onEdit"
          v-if="editable"
        >{{ $t('resto.edit') }}</actions-button>

        <actions-button
          :intent="$pepper.Intent.DANGER"
          icon-pre="trash"
          :size="$pepper.Size.S"
          @click="onDelete"
        />
      </div>
    </div>

    <div class="user-card__row">
      <!-- Address -->
      <div class="user-card__group">
        <ui-icon
          class="user-card__icon"
          glyph="location"
        />

        <address class="user-card__label">{{address}}</address>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'UserAddressCard',

  props: {
    address: {
      type: Object
    },

    editable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    attrs() {
      return {
        class: this.classes,
        href: '#'
      }
    },

    classes() {
      return {
        'user-card-address': true,
        'user-card': true
      }
    }
  },

  methods: {
    onDelete() {
      this.$emit('delete', this.address)
    },

    onEdit() {
      this.$emit('edit', this.address)
    },

    onSetAsDefault() {
      this.$emit('default', this.address)
    }
  }
}
</script>
