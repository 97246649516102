<template>
  <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb :children="breadcrumbs" />
        <div class="row">
          <h1 class="user__title">{{ $t('resto.user_addresses_title') }}</h1>
        </div>
      </header>

      <!-- Loading -->
      <div
        v-if="loading"
        class="user__loader">
        <ui-loader />
      </div>

      <!-- Body:Content -->
      <div
        v-if="!loading"
        class="user__body flow">
        <div class="-grid">
          <card-address
            v-for="(address, i) in addresses"
            :key="i"
            :address="address"
            :editable="canAdd"
            @edit="onEdit"
            @default="onSetAsDefault"
            @delete="onDelete"
          />

          <div
            class="user-card -is-empty"
            v-if="canAdd"
            @click="onAdd">
            <div class="user-card__figure">
              <ui-icon
                class="icon"
                glyph="plus"
              />
            </div>

            <p class="user-card__label">{{ $t('resto.add_new_address') }}</p>
          </div>
        </div>

        <data-empty
          :description="$t('resto.user_no_addresses_description')"
          :title="$t('resto.user_no_addresses')"
          v-if="empty && !canAdd"
        />
      </div>
    </div>

    <!-- Modal Address -->
    <modal-address
      v-if="currentAddress && canAdd"
      :address="edition"
      :errors="errors"
      @close="onClose"
    />
  </main>
</template>

<script>
import CardAddress from '../../components/ui/card-address'
import ModalAddress from '../../components/modals/address'
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import MixinError from '@/helpers/errors'

export default {
  name: "UserAddresses",

  components: {
    CardAddress,
    ModalAddress,
    NavigationsBreadcrumb
  },

  inject: [
    '$embed',
    '$user'
  ],

  mixins: [ MixinError ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('resto.user_account_title'), href: {name: 'sayl-front-user.home'} }, //Your account',
        { label:  this.$t('resto.user_addresses_title'), href: '#' }
      ],
      currentAddress: null,
      edition: {},
      errors: {},
      key: 1,
      loading: false,
    }
  },

  computed: {
    addresses() {
      return this.$basil.get(this.user, 'addresses', [])
    },

    countries() {
      return this.$basil.get(this.$embed, 'shop.countries')
    },

    canAdd() {
      let delivery = this.$basil.get(this.shop, 'services', []).find(s => s.name === 'delivery')
      let ret = !this.$basil.isNil(delivery)

      if(!this.$basil.isNil(this.countries) && !this.$basil.isEmpty(this.countries)) {
        ret = true
      }

      return ret
    },

    empty() {
      return this.addresses.length === 0
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },
  },

  methods: {
    onAdd() {
      this.currentAddress = {}
      this.edition = {
        street: null,
        city: null,
        number: null,
        country: null,
        countryId: null,
        zip: null,
      }

      this.errors = {}
    },

    onClose() {
      this.currentAddress = null
      this.edition = {}
      this.errors = {}
    },

    onConfirm() {
      this.errors = {}
      this.loading = true

      this.$user.setAddress({ address: this.edition })
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    },

    onDelete(address){
      this.$confirm({
        title: this.$t('resto.remove_address_title'),
        description: this.$t('resto.remove_address_description'),
        onPrimary: () => {
          this.loading = true
          this.$user.removeAddress({ id: address.id })
            .catch((e) => $console.error(e))
            .finally(() => this.loading = false)
        }
      })
    },

    onEdit(address) {
      this.edition = address.toAPI()

      this.currentAddress = address
      this.errors = {}
      this.key++
    },

    onSetAsDefault(address) {
      this.loading = true
      let found = this.addresses.find(a => a.isDefault === true)
      let prom = Promise.resolve.bind(Promise, {})

      if(!this.$basil.isNil(found)) {
        found.isDefault = false
        prom = this.$user.setAddress.bind(this.$user, { address: found.toAPI() })
      }

      address.isDefault = true
      let send = address.toAPI()

      prom()
        .then(() => this.$user.setAddress({ address: send }))
        .catch((e) => $console.error(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    if(!this.$basil.get(this.user, 'isLogged')) {
      this.$router.replace({name: 'sayl-front-user.login'}).catch(() => {})
      return
    }

    this.edition = {}
    this.errors = {}
    this.key++
  },
}
</script>
