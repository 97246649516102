<template>
  <layout-modal
    :classes="classes" 
    visible
    closable
    :loading="loading"
    @close="close">

    <forms-address
      :key="key"
      v-model="address"
      :errors="errors"
    />

    <template v-slot:footer>
      <actions-button
        @click="close"
        :size="$pepper.Size.S"
      >{{$t('resto.cancel')}}</actions-button>

      <actions-button
        :appearance="$pepper.Appearance.PRIMARY"
        @click="onConfirm"
        :disabled="loading"
        :size="$pepper.Size.S"
      >{{$t('resto.confirm')}}</actions-button>
    </template>

  </layout-modal>
</template>

<script>
import LayoutModal from '@/components/layouts/modal'

import FormsAddress from '../forms/address'

export default {
  name: 'ModalAddress',

  inject: ['$user'],

  model: {
    prop: 'address',
    event: 'change',
  },

  props: {
    address: {},
  },

  data() {
    return {
      loading: false,
      errors: {},
      key: 1
    }
  },

  components: {
    FormsAddress,
    LayoutModal
  },

  computed: {
    classes(){
      return {
        'view-user-address': true
      }
    }
  },

  methods: {
    close(){
      this.$emit('close')
    },

    handleErrors(e) {
      let status = this.$basil.get(e, 'response.status')
      if(status === 422) {
        let errors = this.$basil.get(e, 'response.data.errors');

        if(!this.$basil.isNil(errors)) {
          let keys = Object.keys(errors);
          keys.forEach(k => this.errors[k] = [this.$t('resto.field_is_required')])// [this.$t(`resto.${errors[k]}`)])
        }
      }

      this.key++;
    },

    onConfirm() {
      this.loading = true;
      this.errors = {};

      this.$user.setAddress({ address: this.address })
        .then(() => this.close())
        .catch((e) => this.handleErrors(e))
        .finally(() => this.loading = false)
    }
  },

  mounted() {
    this.errors = {}
  }
}
</script>
